import React, { useState } from 'react'
import AppanalyticsLogo from '../img/appanalyticslogo.png';
import '../css/pages/faq.css';
import searchButtonImg from '../img/searchButton.png';
import axios from 'axios';
import ExpandMoreIcon from '../img/expand.png';
import { ThreeDots } from  'react-loader-spinner';

// import dashboardImage from '../img/dashboard.png';
// import statisticsImage from '../img/statistics.png';
// import offerImage from '../img/offersPage.png';
// import advertiserImage from '../img/advertiserPage.png';
// import affiliateImage from '../img/affiliatePage.png';
// import offersyncImage from '../img/offersyncPage.png';
// import automationImage from '../img/affiliatePage.png';
// import employeeImage from '../img/employeePage.png';

import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CampaignIcon from '@mui/icons-material/Campaign';
import AddCardIcon from '@mui/icons-material/AddCard';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
// import GetAppIcon from '@mui/icons-material/GetApp';
import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto';
import { scrollToDIV } from '../util';
// import AttachmentIcon from '@mui/icons-material/Attachment';

const FaqPage = () => {
    const [responseCategory, setResponseCategory] = useState([])
    const [loader, setLoader] = useState(false)
    var getHeadingData = async(selectedHeading) => {
        setShowAnswer([])
        setLoader(true);
        await axios({
            method: "POST",
            url: process.env.REACT_APP_BACKEND + "/api/view_faq_data",
            headers:{
                "Content-Type": "application/json",
            },
            data: { 
                "category":selectedHeading
            },
        })
        .then(resp => {
            if(!resp.data.status){
                var tempRespone = []
                for(var index in resp.data){
                    var randomIndex = Math.floor(Math.random()*resp.data.length)
                    if(randomIndex !== parseInt(index)){
                        tempRespone.push({
                            ...resp.data[index],
                            randomIndex
                        })
                    }
                    else{
                        tempRespone.push({
                            ...resp.data[index],
                        })
                    }
                }
                setResponseCategory(tempRespone)
            }
            else{
                setResponseCategory([])
            }
            console.log(resp.data)
        })
        .catch(err=>{
            console.log(err)
        })   
        setLoader(false);

    }
    var categoriesList = [
        {label:'Dashboard',value:'dashboard',color:'rgb(255, 167, 91)',icon:DashboardIcon,details:'Summary of last week/month'},
        {label:'Statistics',value:'statistics',color:'rgb(255, 167, 91)',icon:BarChartIcon,details:'Daily, weekly, offer wise, advertiser wise and many more.'},
        {label:'Offers',value:'offer',color:'rgb(255, 167, 91)',icon:LocalOfferIcon,details:'Offers from different Advertisers.'},
        {label:'Advertiser',value:'advertiser',color:'rgb(255, 167, 91)',icon:CampaignIcon,details:'Maintain your all advertisers in single page.'},
        {label:'Affiliate',value:'affiliate',color:'rgb(255, 167, 91)',icon:PeopleIcon,details:'Maintain your all affiliates in single page.'},
        {label:'Offer Sync',value:'offer_sync',color:'rgb(255, 167, 91)',icon:AddCardIcon,details:'Get different campaigns from different platforms.'},
        {label:'Automation ',value:'automation',color:'rgb(255, 167, 91)',icon:MotionPhotosAutoIcon,details:'Automate each and everything to save time.'},
        {label:'Users Management',value:'employee',color:'rgb(255, 167, 91)',icon:RecordVoiceOverIcon,details:'Maintain your all members in single page.'},
        // {label:'Profile',value:'advertiser',color:'rgb(255, 167, 91)',icon:dashboardImage,details:'Maintain your all advertisers in single page.'},
    ]
    const [selectedHeading, setSelectedHeading] = useState(false)
    const [showAnswer, setShowAnswer] = useState([])

    const parse = require('html-react-parser');

    const questionClicked = (index,force) => {
        if(showAnswer.includes(index) && !force){
            setShowAnswer(showAnswer.filter(e => e!==index));
        }
        else{
            setShowAnswer([...showAnswer,index])
        }
    }
    return (
        <div style={{overflowX:'hidden'}}>             
            <div className='topbarContainer' style={{background:'white',boxShadow:'0 0 10px 1px rgb(115, 75, 0,0.2)',color:'black'}}>
                <div style={{width:'30%'}}><a href='/'><img width={'40%'} src={AppanalyticsLogo} alt=''/></a></div>
                <div style={{fontWeight:'600',fontSize:'18px'}}>FAQs</div>
            </div>
            <div className='faqContainer'>
                <div style={{fontFamily:'Anton',fontSize:'28px'}}>Everything you want to know</div>
                <div style={{fontFamily:'Anton',fontSize:'24px'}}>about <label style={{color:'orange'}}>App Analytics</label></div>
                <div style={{display:'flex',alignItems:'center'}}>
                    <div><img style={{position:'absolute',paddingLeft:'10px',marginTop:'-2px'}} src={searchButtonImg} alt='' width='24px'/></div>
                    <input className='searchFaqText' placeholder='Search your issue'/>
                </div>
                <div className='faqHeadingsContainer'>
                    {categoriesList.map((item,index)=>{
                        return <div key={index} className={selectedHeading ? 'faqHeadingsWrapper hidefaqHeadingsWrapper' : 'faqHeadingsWrapper'} onClick={()=>{getHeadingData(item.value);setSelectedHeading(item.value);}}>
                            <div className={selectedHeading ? 'faqHeadingImage faqHeadingImageHide' : 'faqHeadingImage'} style={{background:'rgb(255, 209, 125)',fontSize:'50px'}}>
                                <item.icon sx={{fontSize:'72px',color:'black'}}/>
                            </div>  
                            <div className={selectedHeading ? 'faqHeadingLabel faqHeadingLabelHide' : 'faqHeadingLabel'} style={{color:(selectedHeading===item.value ? 'orange' : 'black')}}>
                                {item.label}
                            </div>
                            <div className={selectedHeading ? 'faqHeadingDetail faqHeadingDetailHide' : 'faqHeadingDetail'}>
                                {item.details}
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyItems:'center'}}>
                <div className={selectedHeading ? 'faqData' : 'faqData hidefaqData'}>
                    <div style={{cursor:'pointer'}} onClick={()=>{setSelectedHeading(false);setShowAnswer([]);}}>{"< "}Back</div>
                    {loader && 
                        <div style={{display:'flex',height:'30vh',alignItems:'center',justifyContent:'center'}}>
                            <ThreeDots
                                height = "30"
                                width = "30"
                                color = 'orange'
                                ariaLabel = 'three-dots-loading'     
                                wrapperStyle
                                wrapperClass
                            />
                        </div>
                    }
                    {!loader && selectedHeading && responseCategory.map((item,index)=>{
                        return <div className='faqQuestionAnswerContainer' key={index}>
                            <div id={'Question'+index} style={{cursor:'pointer'}} className='faqQuestionContainer' onClick={()=>{questionClicked(index)}}>
                                <div>{'Q'}{". "}{item.question}</div>

                                <div style={{paddingRight:'3%'}}>
                                    <img style={{transition:'all 0.3s linear',userSelect:'none',transform:(showAnswer.includes(index) ? 'rotate(-180deg)' : 'rotate(0deg)')}} src={ExpandMoreIcon} alt='' width='10px'/>
                                </div>
                            </div>
                            <div className={showAnswer.includes(index) ? 'faqAnswerContainer' : 'hidefaqAnswerContainer'}>
                                {/* <div>Created At: {item.createdAt}</div> */}
                                {parse(item.answer)}
                                {
                                   ( item.randomIndex || item.randomIndex===0)
                                    &&
                                    <div style={{fontSize:'14px'}}>
                                        See Also
                                        <label style={{color:'orange',marginLeft:'10px',cursor:'pointer'}} onClick={()=>{scrollToDIV('Question'+item.randomIndex,-80);questionClicked(item.randomIndex,true)}}>
                                            {responseCategory[item.randomIndex].question}
                                        </label>
                                    </div>
                                }
                                <div style={{marginTop:'2px',fontSize:'12px',display:'flex',width:'100%',justifyContent:'flex-end'}}><label style={{fontWeight:'600'}}>Last Updated At: </label> &nbsp; {item.updatedAt ? new Date(item.updatedAt).toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric',hour:'numeric',minute:'numeric' }) : ''}</div>
                            </div>
                        </div>
                    })}
                    {!loader && selectedHeading && responseCategory.length===0 && <div style={{marginTop:'20px'}}>No questions asked in this section.</div>}
                </div>
            </div>
        </div>
    )
}

export default FaqPage