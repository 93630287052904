import React from 'react'

const PrivacyPolicyPage = () => {
    return (
        <div>
        <h1>
            Privacy Policy
        </h1>
        <p>
            AppAnalytics built AppAnalytics Reports : This App is to check your reports and data available on AppAnalytics marketing Platform. 
This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. we will not use or share your information with anyone except as described in this Privacy Policy.
The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at AppAnalytics Report

        </p>

    <h1>
            Information Collection and Use
    </h1>
        <p>
            For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to com.appanalytics.AppAnalyticsReports.
             The information that we request will be retained by us and used as described in this privacy policy.
        </p>

    <h1>
        Service Providers
    </h1>   
        <p>
        we may employ third-party companies and individuals due to the following reasons: <br/>
            •	To facilitate our Service; <br/>
            •	To provide the Service on our behalf;<br/>
            •	To perform Service-related services; <br/>
            •	To assist us in analysing how our Service is used.<br/>
                we want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.

        </p>
    <h1>
        Security
    </h1>
        <p>
            we value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
        </p>
    <h1>
        Children’s Privacy
    </h1>
        <p>
            These Services do not address anyone under the age of 13. we do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
        </p>

    <h1>
        Changes to This Privacy Policy
    </h1>
        <p>
            we may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. we will notify you of any changes by posting the new Privacy Policy on this page.
            This policy is effective as of 2022-07-02
        </p>
    <h1>
        Contact Us
    </h1>
        <p>
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at contact@appanalytics.in.
        </p>
    </div>
    )
}

export default PrivacyPolicyPage