import React, { useState } from 'react'
import AppanalyticsLogo from '../img/appanalyticslogo.png';
import '../css/pages/pricing.css';
import { scrollToDIV } from '../util';
import RightTick from '../img/rightTick.png';
import WrongTick from '../img/wrongTick.png';
import { Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
        ))(({ theme }) => ({
            width: 42,
            height: 26,
            padding: 0,
            '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#a29172' : '#ffa500',
                opacity: 1,
                border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
            },
            '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
            },
            '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#a29172' : '#ffa500',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            },
  }));
  
const PricingPage = () => {
    const pricingDict = {
        'Growth':{
            button:'/signup',
            subheading : 'Growth Subheading',
            price:'249$',
            uptime:'99.99%',
            clicks:'1,000,000',
            conversions:'40,000',
            extra_conversions:'0.25$ / Conversion',
            // impressions:'40,000',
            events: false,
            tracking_domains: 0,
            additional_charge: true,
            custom_domain: false,
            onboarding:'Dedicated Account Manager',
            sla:false,
            dedicated_account_manager:true,
            support:'Email & Skype',
            customer_support:false,
            data_protection:false,
            rules:true,
            alerts:false,
            data_retention:false,
            free_trial:true,
            anti_fraud:false,
            link_checker:false,
            smart_puller:true,
            offer_qty:'Unlimited',
            api_refresh_button:false,
        },
        'Pro':{
            button:'/signup',
            subheading : 'Pro Subheading',
            price:'499$',
            uptime:'99.99%',
            clicks:'Unlimited',
            conversions:'100,000',
            extra_conversions:'0.10$ / conversion',
            // impressions:'400,000',
            events: 10,
            tracking_domains: true,
            additional_charge: false,
            custom_domain: 5,
            onboarding:'Dedicated Account Manager',
            sla:true,
            dedicated_account_manager:true,
            support:'Phone number,Email & Skype',
            customer_support:true,
            data_protection:true,
            rules:true,
            alerts:true,
            data_retention:true,
            free_trial:true,
            anti_fraud:true,
            link_checker:'Upto 1 Lakh Checks',
            smart_puller:true,
            offer_qty:'Unlimited',
            api_refresh_button:true,
        },
        'Custom':{
            button:'/contactus',
            subheading : 'Custom Subheading',
            price:'Contact our expert for better custom plans.',
            uptime:'99.99%',
            clicks:'Custom',
            conversions:'Custom',
            extra_conversions:'Custom / Conversion',
            // impressions:'Custom',
            events: 'events',
            tracking_domains: 'events',
            additional_charge: 'events',
            custom_domain: 'events',
            onboarding:'Dedicated Account Manager',
            sla:true,
            dedicated_account_manager:true,
            support:'Phone number,Email & Skype',
            customer_support:true,
            data_protection:true,
            rules:true,
            alerts:true,
            data_retention:true,
            free_trial:true,
            anti_fraud:true,
            link_checker:'Custom Checks',
            smart_puller:true,
            offer_qty:'Unlimited',
            api_refresh_button:true,
        }
    }
    const features = {
        'Usage Parameters':[
            {label:'Uptime',value:'uptime',type:'text',tooltip:'The amount of hours a website or online service is up and running, and available for its users to access.'},
            {label:'Clicks',value:'clicks',type:'text'},
            {label:'Conversions',value:'conversions',type:'text'},
            {label:'Extra Conversions',value:'extra_conversions',type:'text'},
            // {label:'Impressions',value:'impressions',type:'text'},
            // {label:'Events',value:'events',type:'text'},
            // {label:'Tracking Domains',value:'tracking_domains',type:'text'},
            {label:'Offers',value:'offer_qty',type:'text',tooltip:'Number of offers you can fetch or add on the panel.'},
            // {label:'Additional Charge',value:'additional_charge',type:'text',tooltip:'Price for events that exceeded the number of events included in your plan.'},
            // {label:'Custom Domains',value:'custom_domain',type:'text',tooltip:'Number of your own domains you can use to customize links for tracking.'},
        ],
        'Business Assistance':[
            {label:'Onboarding',value:'onboarding',type:'text',tooltip:'Get our support when making your first steps.'},
            // {label:'SLA',value:'sla',type:'boolean',tooltip:'Maximum time it takes for support to respond.'},
            {label:'Dedicated Account Manager',value:'dedicated_account_manager',type:'boolean',tooltip:'Have a dedicated Account Manager accessible over Skype, email, or Zoom on your plan to achieve your marketing goals with expert insights.'},
            {label:'Customer Support',value:'support',type:'boolean',tooltip:'Multilanguage, 5 star rated customer support'},
            // {label:'Customer Support',value:'customer_support',type:'boolean',tooltip:'Get advices from product experts to grow your project in the most efficient and fastest way.'},
            // {label:'Data Protection',value:'data_protection',type:'boolean'},
        ],
        'Automation':[
            {label:'Smart Puller',value:'smart_puller',type:'boolean',tooltip:'Automatically pull and add campaigns to your panel.'},
            {label:'Rules',value:'rules',type:'boolean',tooltip:'Create rules to automatically perform actions on your campaigns.'},
            // {label:'Alerts',value:'alerts',type:'boolean'},
            // {label:'Data Retention',value:'data_retention',type:'boolean',tooltip:'This is how far back (in days) you have access to your Platform campaign data in the current subscription plan.'},
            {label:'Free Trial',value:'free_trial',type:'boolean'},
            // {label:'Anti Fraud',value:'anti_fraud',type:'boolean'},
            {label:'Link Checker',value:'link_checker',type:'boolean',tooltip:'Check whether the link of the campaigns are working or not working.'},
            {label:'Api Refresh Button',value:'api_refresh_button',type:'boolean'},
        ]
    }

    const getValueFunction = (value) => {
        if(value===true){
            return <img src={RightTick} alt='' width='16px'/>
        }
        if(value===false){
            return <img src={WrongTick} alt='' width='16px'/>
        }
        return value
    }
    
    const [pricingYearly,setPricingYearly] = useState(false)
    return (
        <div>
            <div className='topbarContainer' style={{background:'white',boxShadow:'0 0 10px 1px rgb(115, 75, 0,0.2)',color:'black'}}>
            <div style={{width:'30%'}}><a href='/'><img width={'40%'} src={AppanalyticsLogo} alt=''/></a></div>
                <div style={{fontWeight:'600',fontSize:'18px'}}>Pricing</div>
            </div>
            <div className='pricingContainer'>
                <div className='pricingLabel'>
                    Schedule your success plan <label style={{color:'orange',marginLeft:'10px'}}>now</label>!
                </div>
                <div style={{fontSize:'18px',textAlign:'center',marginTop:'10px'}}>
                    We have several powerful plan to make you start in marketing business.
                </div>
                <div style={{fontSize:'18px',textAlign:'center',marginTop:'20px',display:'flex',justifyContent:"center",alignItems:'center',marginBottom:"-20px"}}>
                    <div style={{marginRight:'20px',fontSize:'20px',color:(pricingYearly ? '' : 'orange')}}>Monthly</div>
                    <IOSSwitch sx={{transform:'scale(1)',marginTop:'5px'}} checked={pricingYearly} onChange={(e)=>{setPricingYearly(e.target.checked);}}/>
                    <div style={{marginLeft:'20px',fontSize:'20px',color:(pricingYearly ? 'orange' : '')}}>
                        Yearly
                        <label style={{position:'absolute',fontSize:'12px',color:'green',marginLeft:'5px'}}>20% Off</label>
                    </div>

                </div>
                <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
                    {
                        Object.keys(pricingDict).map((item,index)=>{
                            return <div className='pricingWrapper'>
                                <div className='pricingPackageName'>
                                    {item}
                                </div>
                                {/* <div style={{marginTop:'5px'}}>
                                    {pricingDict[item].subheading}
                                </div> */}

                                <div style={{marginTop:'30px',fontSize:item==='Custom' ? '20px' : '35px',fontWeight:600}}>
                                    {pricingYearly && parseInt(pricingDict[item].price) ? (parseFloat(pricingDict[item].price.split("$"))*12*0.8).toFixed(2) + "$" : pricingDict[item].price}
                                </div>
                                
                                <ul style={{marginTop:'30px',marginLeft:'-20px',fontSize:'15px'}}>
                                    <li className='listValues'>
                                        {pricingDict[item].uptime + ' Uptime'}
                                    </li>
                                    <li className='listValues'>
                                        {pricingDict[item].clicks + ' Clicks'}
                                    </li>
                                    <li className='listValues'>
                                        {pricingDict[item].conversions + ' Conversions'}
                                    </li>
                                    {/* <li className='listValues'>
                                        {pricingDict[item].extra_conversions}
                                    </li>
                                    <li className='listValues'>
                                        {pricingDict[item].impressions + ' Impressions'}
                                    </li> */}
                                </ul>
                                <div style={{display:'flex',justifyContent:'center',position:'relative',marginTop:'40px',marginBottom:'40px'}}>
                                    <div className="priceButtonWrapper">
                                        <div className="priceButtonLinkWrapper">
                                            <a className='priceButtonLink' href={'/freetrial'}>
                                            {/* <a className='priceButtonLink' href={pricingDict[item].button}> */}
                                                {pricingDict[item].button === '/signup' ? 'Sign Up' : 'Contact Us'}
                                            </a>
                                            <div class="priceButtonIcon">
                                                {pricingDict[item].button === '/signup' 
                                                &&
                                                <svg className='priceButtonSvg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.832 268.832">
                                                    <path d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z"/>
                                                </svg>
                                                }
                                                {pricingDict[item].button !== '/signup' 
                                                &&
                                                <svg className='priceButtonSvg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110">
                                                    <path d="M70.25,112.11H5.94c-5.09-0.39-6.32-4.05-5.85-8.28c2.79-24.92,30.74-17.71,41.97-27.82l0,0 c5.6,16.48,29.01,17.09,34.26,0c1.21,1.09,2.89,2.01,4.86,2.82c-1.81,1.1-3.52,2.43-5.08,3.99 C68.18,90.73,66.23,102.36,70.25,112.11L70.25,112.11z M110,86.96c8.22,8.22,8.22,21.54,0,29.76c-8.22,8.22-21.54,8.22-29.76,0 s-8.22-21.54,0-29.76C88.46,78.74,101.79,78.74,110,86.96L110,86.96L110,86.96z M92.97,90.16h4.3c0.59,0,1.07,0.48,1.07,1.07v7.38 h7.38c0.59,0,1.07,0.48,1.07,1.07v4.3c0,0.59-0.48,1.07-1.07,1.07h-7.38v7.38c0,0.59-0.48,1.07-1.07,1.07h-4.3 c-0.59,0-1.07-0.48-1.07-1.07v-7.38h-7.38c-0.59,0-1.07-0.48-1.07-1.07v-4.3c0-0.59,0.48-1.07,1.07-1.07h7.38v-7.38 C91.9,90.65,92.38,90.16,92.97,90.16L92.97,90.16z M41.91,59.48c2.13,3.37,4.35,6.82,7.11,9.36c2.66,2.43,5.89,4.08,10.15,4.09 c4.63,0.01,8-1.7,10.75-4.27c2.86-2.67,5.1-6.33,7.33-9.99l5.97-9.83c1.11-2.55,1.52-4.24,1.26-5.24 c-0.16-0.59-0.81-0.88-1.92-0.93c-0.23-0.01-0.48-0.01-0.72-0.01c-0.26,0.01-0.54,0.03-0.84,0.05c-0.17,0.01-0.31,0-0.46-0.03 c-0.52,0.03-1.08-0.01-1.63-0.09l2.04-9.05c-15.17,2.39-26.51-8.87-42.53-2.25l1.16,10.66c-0.63,0.04-1.25,0.01-1.82-0.07 C28.56,42.18,40.1,56.59,41.91,59.48L41.91,59.48L41.91,59.48z M84.62,39.96c1.47,0.45,2.41,1.38,2.8,2.89 c0.43,1.67-0.04,4.02-1.46,7.24l0,0c-0.03,0.06-0.05,0.12-0.09,0.17l-6.03,9.94c-2.33,3.83-4.68,7.68-7.84,10.62 c-3.26,3.06-7.29,5.09-12.79,5.07c-5.13-0.01-9.01-1.97-12.18-4.88c-3.83-3.52-21.49-25.62-13.6-30.95 c0.39-0.25,0.82-0.48,1.28-0.65c-0.35-4.57-0.47-10.33-0.25-15.15c0.12-1.14,0.34-2.28,0.65-3.43c1.35-4.84,4.75-8.74,8.95-11.41 c2.32-1.48,4.86-2.59,7.5-3.33c1.68-0.48-1.43-5.86,0.3-6.02c8.4-0.87,22.02,6.81,27.89,13.17c2.93,3.18,4.79,7.4,5.19,12.98 L84.62,39.96L84.62,39.96L84.62,39.96L84.62,39.96L84.62,39.96z"/>
                                                </svg>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        })
                    }
                </div>
                <div style={{display:'flex',justifyContent:'center',marginTop:'40px'}}>
                    <div>
                        <button className="pricingCompareButton" onClick={()=>{scrollToDIV('priceCompare',-80)}}>
                            Compare
                        </button>
                    </div>
                </div>
                <div id='priceCompare' style={{marginTop:'30px'}}>
                    <div className='pricingCompareTableHead'>
                        <div className='pricingCompareTableFirstColumn pricingColumnHead'>
                            Features
                        </div>
                        {Object.keys(pricingDict).map((item,index)=>{
                            return <div key={index} className='pricingCompareTableNextColumns pricingColumnHead'>
                                        {item}
                                    </div>
                        })}
                    </div>
                    <div>
                        {Object.keys(features).map((item,index)=>{
                            return <div key={index} style={{paddingLeft:'5%',paddingRight:'5%',marginTop:'10px'}}>
                                        <div className='pricingCompareTableValueHead pricingFeatureHeading'>
                                            {item}
                                            
                                        </div>
                                        <div style={{background:'#fff5e674'}}>
                                            {features[item].map((childItem,childIndex)=>{
                                                return <div key={childIndex} className={'pricingCompareTableValue'}>
                                                            <div className='pricingCompareTableFirstColumn pricingFeatureValue' style={{display:'flex',alignItem:'center'}}>
                                                               
                                                                {childItem.label}
                                                                {childItem.tooltip &&
                                                                    <Tooltip title={childItem.tooltip}>
                                                                        <div style={{marginLeft:'5px',paddingTop:"2px"}}>
                                                                            <HelpIcon sx={{fontSize:'14px'}}/>
                                                                        </div>
                                                                    </Tooltip>
                                                                    }
                                                            </div>
                                                            {Object.keys(pricingDict).map((item,index)=>{
                                                                return <div key={index} className='pricingCompareTableNextColumns pricingFeatureValue'>
                                                                            {getValueFunction(pricingDict[item][childItem.value])}
                                                                        </div>
                                                            })}
                                                        </div>
                                            })}
                                        </div>
                                    </div>
                        })}
                    </div>

                </div>
            </div>
            <br/>
            <br/>
        </div>
    )
}

export default PricingPage