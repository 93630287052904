import React from 'react'
import '../css/pages/solutions.css';
import AgenciesAndNetwork from '../img/agenciesAndNetwork.png'
import brandAndAdvertiser from '../img/brandsAndAdvertiser.png'
import influencersAndMarketers from '../img/influencersAndMarketers.png'
const SolutionsPage = () => {
   
    return (
        <div className='solutionPageContainer'>
            <div className='solutionHeading'>
                Viable Solution for All Business  
            </div>
            <div className='solutionSubHeading'>
                Our SaaS Platform is built to meet all needs of Marketing Industry .  
            </div>
            <div className='solutionsContainer'>
                <div className='solutionWrapper'>
                    <img src={AgenciesAndNetwork} width={'80%'} alt=''/>
                    <div className='particularSolutionHeading'>
                        <b>Agencies and Network</b>
                    </div>
                    <div className='solutionText'>
                        One platform to track, measure business performance and modernize the process of affiliate networks.
                    </div>
                </div>
                <div className='solutionWrapper'>
                    <img src={brandAndAdvertiser} width={'80%'} alt=''/>
                    <div className='particularSolutionHeading'>
                        <b>Brands & Advertisers</b>
                    </div>
                    <div className='solutionText' >
                        Easy to tie-up with direct publishers & Rapid integration format helps agencies to complete integration with their advertisers. 
                    </div>
    
                </div>
                <div className='solutionWrapper'>
                    <img src={influencersAndMarketers} width={'80%'} alt=''/>
                    <div className='particularSolutionHeading'>
                        <b>Influencers & Marketer</b>
                    </div>
                    <div className='solutionText' >
                        Gather and analyze data from all areas that affect your online store and use it to discover consumer behavior to result in more sales. 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SolutionsPage