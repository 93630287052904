import React from 'react'
import { scrollToDIV } from '../util'
import LinkedInLogo from '../img/linkedin.png';
import '../css/components/footer.css';
const Footer = () => {
    return (
        <div className='footerContainer' style={{background:'orange',display:"flex",justifyContent:'space-around',padding:'20px'}}>
            <div className='footerWrapper'>
                <div style={{marginBottom:'10px',fontSize:'17px',fontWeight:600}}>Our Company</div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}} onClick={()=>{scrollToDIV('about')}}>
                    About
                </div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}}>
                    <a href='/privacy-policy.html' style={{textDecoration:'none',color:'black'}}>Privacy Policy</a>
                </div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}}>
                    <a href='/signup' style={{textDecoration:'none',color:'black'}}> Sign Up </a>
                </div>

                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}}>
                    <a href='/faq' style={{textDecoration:'none',color:'black'}}> FAQs </a>
                </div>
            </div>
            <div className='footerWrapper'>
                <div style={{marginBottom:'10px',fontSize:'17px',fontWeight:600}}>Features</div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}} onClick={()=>{scrollToDIV('features')}}>
                    Tracking and Analytics
                </div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}} onClick={()=>{scrollToDIV('features')}}>
                    Anti-Fraud
                </div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}} onClick={()=>{scrollToDIV('features')}}>
                    Automation
                </div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}} onClick={()=>{scrollToDIV('features')}}>
                    White-Label
                </div>
            </div>
            <div className='footerWrapper'>
                <div style={{marginBottom:'10px',fontSize:'17px',fontWeight:600}}>Solutions</div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}} onClick={()=>{scrollToDIV('solutions')}}>
                    Agencies and Network
                </div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}} onClick={()=>{scrollToDIV('solutions')}}>
                    Brands and Advertiser
                </div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}} onClick={()=>{scrollToDIV('solutions')}}>
                    Influencer and Marketer
                </div>
            </div>
            <div className='footerWrapper'>
                <div style={{marginBottom:'10px',fontSize:'17px',fontWeight:600}}>Contact Us</div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}}>
                    Noida, India
                </div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer'}}>
                    contact@appanalytics.in
                </div>
                <div style={{marginBottom:'5px',fontSize:'14px',fontWeight:400,cursor:'pointer',display:'flex'}}>
                    <a href='https://www.linkedin.com/company/app-analytics/' target='_blank' rel='noreferrer'><img src={LinkedInLogo} alt=''></img></a>
                </div>
            </div>
        </div>
    )
}

export default Footer