import React from 'react'
import AppanalyticsLogo from '../img/appanalyticslogo.png';
import '../css/pages/newfeatures.css';
import ClocksSvg from '../img/clocks.svg'
const NewFeatures = () => {

    var features = [
        {heading:'Day wise offer statistics',subheading:'This feature will provide a benefit that you will be able to see performance of offer day wise from 3 to 7 days',updatedAt:'2022-08-19T13:57:00'},
        {heading:'Bookmark Offers',subheading:'This feature will provide a benefit that you will be able to bookmark offers and can see them anytime at one click ahead',updatedAt:'2022-08-21T13:57:00'},
        {heading:'Offer Landing Pages And Logos',subheading:'This feature will provide a benefit of that the you will be able to see logo with offer title',updatedAt:'2022-08-25T13:57:00'},
        {heading:'Over Caps',subheading:'This feature will provide a benefit of that the link will automatically pause if caps reached',updatedAt:'2022-08-31T00:00:00'},
        // {heading:'Cut Back',subheading:'This feature will provide a benefit of that the link you will be able to cut Approved conversions at your end',updatedAt:'2022-09-02T00:00:00'},
        {heading:'Smart Links',subheading:'This feature will provide a benefit to create a single link for multiple offers',updatedAt:'2022-09-05T00:00:00'},
    ]
    features = features.reverse()

    var featuresRight = []
    var featuresLeft = []

    for(var index in features){
        if(parseInt(index)%2===0){
            featuresRight.push(features[index])
        }
        else{
            featuresLeft.push(features[index])
        }
    }
    const svgComponent = (item,right) => {
        var textStyle = {
            fontSize:'3.5px',
            fontFamily:'arial',
            fontWeight:700,
        }
        if(!right){
            textStyle['transform'] = 'scaleX(-1)'
        }
        return <svg viewBox='0 0 75 41' width={'100%'} height={'190px'}>
            <defs>
                <linearGradient id="newFeatureGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%"   stop-color="#ff6600"/>
                    <stop offset="20%"   stop-color="#ff781f"/>
                    <stop offset="40%"   stop-color="#ff8b3d"/>
                    <stop offset="60%"   stop-color="#ff9d5c"/>
                    <stop offset="80%"   stop-color="#ff9d5c"/>
                    <stop offset="100%"   stop-color="#ffaf7a"/>
                </linearGradient>
                <filter id="f1" x="0" y="0" width="200%" height="200%">
                    <feOffset result="offOut" in="SourceGraphic" dx="1" dy="0" />
                    <feColorMatrix result="matrixOut" in="offOut" type="matrix"
                    values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0" />
                    <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="0.5" />
                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                </filter>
            </defs>
            <path filter="url(#f1)" className='newFeatureSvg' d='M 16 0 C 7 0 7 0 7 14 L 0 20 L 7 26 C 7 40 7 40 16 40 L 68 40 C 72 40 72 40 72 35 C 72 34 72 33 72 32 C 72 28 71 28 71 32 C 71 39 71 39 68 39 L 16 39 C 8 39 8 39 8 25 L 2 20 L 8 15 C 8 1 8 1 16 1 L 16 0'></path>
            <g style={textStyle}>
                <foreignObject x={right ? 13 : -73} y="4" width="60" height="200">
                    <div style={{fontSize:'4.5px',paddingBottom:'2px',textAlign:(right ? 'left' : 'right')}}>{item.heading}</div>
                    <div style={{display:'flex',flexDirection:(right ? 'row' : 'row-reverse')}}>
                        <p style={{width:'75%',fontSize:'2.9px',lineHeight:'3px',fontWeight:'400',textAlign:'justify' }}>{item.subheading}</p>
                        <div style={{width:'25%',fontSize:'1px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <img src={ClocksSvg} alt='' width={'60%'}/>
                        </div>
                    </div>
                </foreignObject>
                <text x={right ? 13 : -13} text-anchor={right ? 'start' : 'end'} y="7">
                    
                </text>
            </g>
                
        </svg>
    }
    return (
        <div id='newFeaturesPage' style={{position:'relative'}}>
            <div className='topbarContainer' style={{background:'white',boxShadow:'0 0 10px 1px rgb(115, 75, 0,0.2)',color:'black'}}>
                <div style={{width:'30%'}}><a href='/'><img width={'40%'} src={AppanalyticsLogo} alt=''/></a></div>
                <div style={{fontWeight:'600',fontSize:'18px'}}>New Features</div>
            </div>
            <div style={{marginTop:'80px',display:'flex',justifyContent:'center',color:'orange',fontSize:'32px',fontFamily:'anton'}}>
                <div style={{borderBottom:'4px solid black',width:'max-content'}}>
                    Features
                </div>
            </div>
            <div className='newFeaturesContainer' style={{position:'absolute'}}>
                <div style={{width:"45%"}}>
                    {featuresLeft.map((item,index)=>{
                        return <div key={index} className='rightSideSvgNewFeature leftSideSvgNewFeature'>
                                    
                                    <div className='monthYear' style={{position:'absolute',left:"-75px",marginTop:'-38px',transform:'scaleX(-1)'}}>
                                        <div>
                                            {new Date(item.updatedAt).toLocaleDateString("en-US", {month: 'short',day:'numeric'})}
                                        </div>
                                        <div>
                                            {new Date(item.updatedAt).toLocaleDateString("en-US", {year: 'numeric'})}
                                        </div>
                                    </div>
                                    <div className='svgContainer'>
                                        {svgComponent(item)}
                                    </div>
                                </div>
                    })}
                </div>
                <div style={{width:"4px",background:'black'}}>
                    
                </div>
                <div style={{width:"45%"}}>
                    {featuresRight.map((item,index)=>{
                        return <div key={index} className='rightSideSvgNewFeature'>
                                    <div className='monthYear' style={{position:'absolute',left:"-79px",marginTop:'-38px'}}>
                                        <div>
                                            {new Date(item.updatedAt).toLocaleDateString("en-US", {month: 'short',day:'numeric'})}
                                        </div>
                                        <div>
                                            {new Date(item.updatedAt).toLocaleDateString("en-US", {year: 'numeric'})}
                                        </div>
                                    </div>
                                    <div className='svgContainer'>
                                        {svgComponent(item,true)}
                                    </div>
                                </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default NewFeatures