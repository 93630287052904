import React from 'react'
import '../css/pages/features.css';
import antiFraudImg from '../img/antiFraud.png';
import trackingAndAnalyticsImg from '../img/trackingAndAnalytics.png';
import automationImg from '../img/automation.png';
import whiteLabelImg from '../img/whiteLabel.png';
const FeaturesPage = () => {

    return (
        <div className='featureContainer'>
            <div style={{marginBottom:'1%'}}>
                <div className='featureMainHeading'>Features to Count on for Growth!!</div>
                <div className='featurePageSubHeading'>
                    With change in technology and time, our team has continuously worked on improvements to make the solution better. 
                    <br/>
                    <div>Our dedicated R&D team overlooks the requirement of the industry and try to adapt swiftly.  </div>
                </div>
            </div>
            <div style={{width:'calc(100vw - 6%)',marginLeft:'3%',marginRight:'3%'}}>
                <div className='featureRowOne'>
                    <div className='featureRowOneColOne'>
                        <div className='featureDetailTextLeftSide'>
                            <div className='featureHeading'>Tracking And Analytics</div>
                            <div className='featureSubHeading'>
                                Extra targeting possibilities while adhering to a set of guidelines. Track & Analysis can go beyond geo to include carriers, languages, and delivering campaigns to the correct people.
                            </div>
                        </div>
                        <svg className='leftSideSVG' viewBox="200 0 350 600" height='600' width='400'>
                            <defs>
                                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%"   stop-color="#05a"/>
                                <stop offset="100%" stop-color="#0a5"/>
                                </linearGradient>
                            </defs>
                            <g>
                                <path className='featurespath' fill="none" stroke-width="10" d="M 408 360 A 168 168 0 1 1 552 192 L 552 312 L 552 360 L 552 540 L 516 408 Q 492 348 408 360 " />
                                <circle  cx="385" cy="195" r="140" stroke-width="3" fill="white" />
                                <image x="280" y="90" href={trackingAndAnalyticsImg} height="220" width="220"/>
                            </g>
                        </svg>
                    </div>
                    <div className='featureRowOneColTwo'>
                        <svg className='rightSideSVG'  viewBox="200 0 355 600" height='600' width='400'>
                            <g>
                                <path className='featurespath' style={{marginLeft:'-200px'}} fill="none" transform="translate(770), scale(-1,1)" stroke-width="10" d="M 408 360 A 168 168 0 1 1 552 192 L 552 312 L 552 360 L 552 540 L 516 408 Q 492 348 408 360 " />
                                <circle  cx="385" cy="195" r="140" stroke-width="3" fill="white" />
                                <image x="280" y="90" href={antiFraudImg} height="220" width="220"/>
                            </g>
                        </svg>
                        <div className='featureDetailTextRightSide'>
                            <div className='featureHeading'>Anti-Fraud</div>
                            <div className='featureSubHeading'>
                                Anti-Fraud features not only to detect fraudulent traffic but also prevent the traffic. No malicious traffic will go unnoticed from our in-house fraud detection feature.
                            </div>
                        </div>
                    </div>

                </div>
                <div className='featureRowTwo'>
                    <div className='featureRowTwoColOne'>
                        <div className='featureDetailTextLeftSide'>
                            <div className='featureHeading'>Automation</div>
                            <div className='featureSubHeading'>
                            App Analytics  recognizes the importance of automation and continues to develop new automated capabilities that can boost production while lowering costs.
                            </div>
                        </div>
                        <svg className='leftSideSVG' viewBox="200 0 350 600" height='600' width='400'>
                            <g>
                                <path className='featurespath' fill="none" stroke-width="10" d="M 408 360 A 168 168 0 1 1 552 192 L 552 312 L 552 360 L 552 540 L 516 408 Q 492 348 408 360 " />
                                <circle  cx="385" cy="195" r="140" stroke-width="3" fill="white" />
                                <image x="265" y="80" href={automationImg} height="220" width="220"/>
                            </g>
                        </svg>
                    </div>
                    <div className='featureRowTwoColTwo'>
                        <svg className='rightSideSVG'  viewBox="200 0 355 600" height='600' width='400'>
                            <g>
                                <path className='featurespath' fill="none" transform="translate(770), scale(-1,1)" stroke-width="10" d="M 408 360 A 168 168 0 1 1 552 192 L 552 312 L 552 360 L 552 540 L 516 408 Q 492 348 408 360 " />
                                <circle  cx="385" cy="195" r="140" stroke-width="3" fill="white" />
                                <image x="280" y="90" href={whiteLabelImg} height="220" width="220"/>
                            </g>
                        </svg>
                        <div className='featureDetailTextRightSide'>
                            <div className='featureHeading'>Whitelabel</div>
                            <div className='featureSubHeading'>
                            App Analytics has introduced a complete white-label solution for managing their affiliate network and through which users can show their brands to their partners.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturesPage