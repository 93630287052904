
export function sortingV2(dictionary,keyword,sortingOrder=true,key) {
    dictionary.sort((a, b) => {
        var previousValue = a[keyword]
        var nextValue = b[keyword]
        if(key){
            previousValue = previousValue[key]
            nextValue = nextValue[key]
        }
        if (sortingOrder===true){
            if(typeof previousValue ==='number'){
                var temp = previousValue - nextValue;
                return temp
            }
            else{
                if(!previousValue && previousValue!==0){
                    previousValue = ''
                }
                if(!nextValue && nextValue!==0){
                    nextValue = ''
                }
                if ((previousValue ? previousValue.toString().toLowerCase() : previousValue) < (nextValue ? nextValue.toString().toLowerCase() : nextValue)) return -1
                return (previousValue ? previousValue.toString().toLowerCase() : previousValue) > (nextValue ? nextValue.toString().toLowerCase() : nextValue) ? 1 : 0
            }
        }
        else{
            if(typeof previousValue ==='number'){
                temp = nextValue - previousValue;
                return temp
            }
            else{
                if(!previousValue && previousValue!==0){
                    previousValue = ''
                }
                if(!nextValue && nextValue!==0){
                    nextValue = ''
                }
                if ((nextValue ? nextValue.toString().toLowerCase() : nextValue) < (previousValue ? previousValue.toString().toLowerCase() : previousValue)) return -1
                return (nextValue ? nextValue.toString().toLowerCase() : nextValue) > (previousValue ? previousValue.toString().toLowerCase() : previousValue) ? 1 : 0
            }
            
        }
    })
}

