import React, { useState } from 'react'
import '../css/pages/signup.css';
// import AwesomeSlider from 'react-awesome-slider';
// import withAutoplay from 'react-awesome-slider/dist/autoplay';
// import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import appanalyticsLogo from '../img/appanalyticslogo.png';
// const AutoplaySlider = withAutoplay(AwesomeSlider);
import FreeTrialImg from '../img/freetrial.png'
import axios from 'axios';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Bars} from  'react-loader-spinner'

const SignUpPage = () => {

    const [signupDetails, setSignupDetails] = useState({account_type:'individual',business_type:'Blogger'})
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [response, setResponse] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    var uploadSystem = async() => {
        setShowLoader(true);
        await axios({
            method: "POST",
            url: process.env.REACT_APP_BACKEND + "/api/freetrial",
            headers:{
                "Content-Type": "application/json",
            },
            data: { 
                "name":signupDetails.name,
                "email-id":signupDetails.email,
                "account-type":signupDetails.account_type,
                "business-type":signupDetails.business_type,
                "skype-id":signupDetails.skype ? signupDetails.skype : "",
                "phone-number" : signupDetails.phone_number ? signupDetails.phone_number : "",
            },
        })
        .then(resp => {
            setResponse(true);
            setShowLoader(false);
        })
        .catch(err=>{
            setShowLoader(false);
            console.log(err)
        })
        
    }
    return (
        <div id='signupPage' style={{width:'100vw',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center',overflowX:'hidden'}} onClick={()=>{if(response){window.location.href="/"}}}>
            <div className='signupWrapper' style={response ? {filter:'blur(2px)'} : {}}>
                <div className='signupWrapperLeftContainer'>     
                    <img src={FreeTrialImg} width='90%' alt=''></img>
                </div>
                <div className='signupWrapperRightContainer'>     
                    <div style={{display:'flex',justifyContent:'center',marginBottom:'20px'}}>
                        <img src={appanalyticsLogo} className='signupPageLogo' onClick={()=>{window.location.href='/'}} alt=''></img>
                    </div>
                    {/* <div className='heading' style={{fontSize:'32px',fontWeight:'600',marginBottom:'20px'}}>Get Started.</div>  */}
                        <div className='formData'> 
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div style={{width:'48%'}}>
                                    <div className='signupLabel'>
                                        Name<label style={{color:'red'}}>*</label>
                                    </div>
                                    <input className='inputSignUp' onChange={(e)=>{setSignupDetails({...signupDetails,name:e.target.value})}}/>
                                </div>

                                <div style={{width:'48%'}}>
                                    <div className='signupLabel'>
                                        Email address<label style={{color:'red'}}>*</label>
                                    </div>
                                    <input className={emailInvalid ? 'inputSignUp invalid' : 'inputSignUp'} type='email' onChange={(e)=>{setSignupDetails({...signupDetails,email:e.target.value});if(!e.target.value.match(emailRegex)){setEmailInvalid(true);}else{setEmailInvalid(false);}}}/>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        {emailInvalid && <div style={{color:'red',fontSize:'10px',position:'absolute',paddingTop:'5px'}}>Invalid Email</div>}
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'flex'}}>
                                <div style={{width:'52%'}}>
                                    <div className='signupLabel'>
                                        Choose Account Type<label style={{color:'red'}}>*</label>
                                    </div>
                                    <div style={{display:'flex',width:'max-content',minHeight:'30px',alignItems:'center',userSelect:'none',cursor:'pointer'}}>
                                        <div style={{padding:'2%',paddingLeft:'5%',paddingRight:'5%',color:signupDetails.account_type==='individual' ? 'orange' :'rgb(80,80,80)',border:signupDetails.account_type==='individual' ? '1px solid orange' : 'none'}} onClick={(e)=>{setSignupDetails({...signupDetails,account_type:'individual'})}}>Individual</div>
                                        <div style={{padding:'2%',paddingLeft:'5%',paddingRight:'5%',color:signupDetails.account_type==='company' ? 'orange' :'rgb(80,80,80)',border:signupDetails.account_type==='company' ? '1px solid orange' : 'none'}} onClick={(e)=>{setSignupDetails({...signupDetails,account_type:'company'})}}>Company</div>
                                    </div>
                                </div>
                                <div>
                                    <div className='signupLabel'>
                                        Choose Business Type<label style={{color:'red'}}>*</label>
                                    </div>
                                    <select className='inputSignUp' style={{color:'black',minHeight:'32px'}} onChange={(e)=>{setSignupDetails({...signupDetails,business_type:e.target.value})}}>
                                        <option value="Blogger">Blogger</option>
                                        <option value="Influencer">Influencer</option>
                                        <option value="Website Owner">Website Owner</option>
                                        <option value="Agency">Agency</option>
                                        <option value="Network">Network</option>
                                        <option value="Freelancer">Freelancer</option>
                                        <option value="Application Developer">Application Developer</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>

                            
                            <div className='signupLabel'>
                                Skype Address
                            </div>
                            <input className='inputSignUp' type='email' onChange={(e)=>{setSignupDetails({...signupDetails,skype:e.target.value})}}/>

                            <div className='signupLabel'>
                                Phone Number
                            </div>
                            <input className='inputSignUp' type='email' onChange={(e)=>{setSignupDetails({...signupDetails,phone_number:e.target.value})}}/>

                            {/* <div style={{display:'flex',alignItems:'center',marginTop:'20px',fontSize:'12px'}}>
                                <input id='privacy_policy_check' type='checkbox' onChange={(e)=>{setSignupDetails({...signupDetails,privacy_policy:e.target.checked})}}/>
                                <label htmlFor='privacy_policy_check'> I agree to <a style={{textDecoration:'none',color:'blue'}} href={'/privacy-policy.html'} target='_blank' rel='noreferrer'>privacy policy</a></label>
                            </div> */}
                            <div style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
                                <button className="button-87" disabled={(emailInvalid || !signupDetails.email || !signupDetails.name )} style={(emailInvalid || !signupDetails.email || !signupDetails.name) ? {background:'grey',textTransform:'none',color:'rgb(230,230,230)',cursor:'not-allowed'} : {fontSize:'12px',textTransform:'none'}} onClick={()=>{uploadSystem()}}>
                                    {showLoader && 
                                        <Bars              
                                            ariaLabel="loading-indicator"
                                            height={20}
                                            width={20}
                                            strokeWidth={5}
                                            color="white"
                                            />}
                                    {!showLoader && <span>Submit</span>}
                                </button>
                            </div>
                                
                        </div>
                </div>

            </div>

            {
                response &&
                <div style={{position:'absolute',width:'300px',minHeight:'200px',background:'white',paddingLeft:'10px',paddingRight:'10px',boxShadow:'0 0 5px 1px grey',display:"flex",alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <img src={require('../img/success.png')} width='50px' alt=''></img>
                    <br/>
                    <div style={{textAlign:'center',userSelect:'none'}}>Your request for trial has been recorded, <br/> someone will get in contact with you soon.</div>
                </div>
            }
        </div>
    )
}

export default SignUpPage