import React, { useState } from 'react'
import AppanalyticsLogo from '../../img/appanalyticslogo.png';
import '../../css/pages/helpcenter/helpcenter.css';
import StepsImage from '../../img/helpcenter/steps.png'
import NetworkManagerImage from '../../img/helpcenter/networkManager.png'
import AffiliatesImage from '../../img/helpcenter/affiliates.jpg'
import TipAndTricksImage from '../../img/helpcenter/tipstricks.webp'
import GraffitiImage from '../../img/helpcenter/graffiti.png'
import { HelpCenterData } from './helpcenterData';
import { sortingV2 } from '../../util/util';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';

const HelpCenterMain = () => {
    // const parse = require('html-react-parser');
    var data = {
        'Getting Started':{
            img:<img src={StepsImage} height={'150px'} alt=''/>,
            description:'Welcome to App Analytics! Get started faster by learning some basics.',
            childItems:[
            ],
        },
        'Network Managers':{
            img:<img src={NetworkManagerImage} height={'150px'} alt=''/>,
            description:'Network managers can find various features available for them to use, and how to use.',
            childItems:[
                {
                    dateAdded:'Mon Sep 14 2020 13:50:10 GMT+0530 (India Standard Time)',
                    articleName:'Smartlinks',
                    writtenBy:'Deepesh Motwani',
                    data:HelpCenterData.Smartlinks
                },
            ],
        },
        'Affiliates':{
            img:<img src={AffiliatesImage} height={'150px'} alt=''/>,
            description:'How can an affiliate use affiliate panel to boost up performance?',
            childItems:[
            ],
        },
        'Tips And Tricks':{
            img:<img src={TipAndTricksImage} height={'150px'} alt=''/>,
            description:'Various tips, tricks, hidden features and easter eggs!',
            childItems:[],
        }
    }
    var allData = []
    for(var index in data){
        for(var childItemIndex in data[index].childItems){
            allData.push({...data[index].childItems[childItemIndex],keyType:index})
        }
    }
    sortingV2(allData,'dateAdded',false)
    
    var newData = allData.slice(0,20)

    var url = window.location.href
    if(url.split('/helpcenter').length>1 && url.split('/helpcenter')[1]!==''){
        var pagesList = url.split('/helpcenter')[1].split('/')
        var tempSelectedTab = pagesList[1].replace("%20",' ')
        var tempSelectedTabPage = pagesList[2]
    }

    const [selectedTab, setSelectedTab] = useState(tempSelectedTab ? tempSelectedTab : false)
    const [selectedSubTab, setSelectedSubTab] = useState(tempSelectedTabPage ? tempSelectedTabPage : false)
    
    const addToUrl = (key1,key2='') => {
        var url = '/helpcenter'
        if(key1){
            url += "/" + key1 + '/' + key2
        }
        window.history.pushState(key2 ? key2 : key1,'',url)
    }
    const getNavigationBar = (element1,element2,element3) => {
        var list = []
        if(element1){
            list.push(element1)
        }
        if(element2){
            list.push(element2)
        }
        if(element3){
            list.push(element3)
        }
        return <div style={{display:'flex',alignItems:'center',paddingTop:'30px',paddingBottom:'10px'}}>
            {list.map((item,index)=>{
                return <div style={{display:'flex',alignItems:'center',fontSize:'13px',cursor:'pointer'}}
                            onClick={()=>{
                                if(index===0){
                                    setSelectedTab(false);
                                    setSelectedSubTab(false);
                                }
                                if(index===1){
                                    setSelectedSubTab(false);
                                }
                            }}
                            >
                        {item}  {index!==list.length-1 && <div style={{paddingLeft:'5px',paddingRight:'5px',cursor:'default'}}>{'>'}</div>}
                    </div>
            })}
        </div>
    }
    return (
        <div>
            <div className='topbarContainer' style={{background:'white',boxShadow:'0 0 10px 1px rgb(115, 75, 0,0.2)',color:'black'}}>
                <div style={{width:'30%'}}><a href='/'><img width={'40%'} src={AppanalyticsLogo} alt=''/></a></div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-around',width:'60%'}}>
                    <div style={{fontWeight:'500',fontSize:'16px'}} onClick={()=>{setSelectedTab(false);setSelectedSubTab(false);addToUrl('');}}>Help Center</div>
                    <div style={{fontWeight:'400',fontSize:'16px'}} onClick={()=>{setSelectedTab('Network Managers');setSelectedSubTab(false);addToUrl('Network Managers');}}>For Networks</div>
                    <div style={{fontWeight:'400',fontSize:'16px'}} onClick={()=>{setSelectedTab('Affiliates');setSelectedSubTab(false);addToUrl('Affiliates');}}>For Affiliates</div>
                    <div style={{fontWeight:'400',fontSize:'16px'}} onClick={()=>{setSelectedTab('Getting Started');setSelectedSubTab(false);addToUrl('Getting Started');}}>For Trial</div>
                    <div style={{fontWeight:'400',fontSize:'16px'}} onClick={()=>{setSelectedTab('Tips And Tricks');setSelectedSubTab(false);addToUrl('Tips And Tricks');}}>Tips And Tricks</div>
                </div>
            </div>
            {selectedTab===false
            &&
            <>
                <div className='helpcenterHeadingContainer'>
                    <div>
                        Welcome to App Analytics Help Center
                        <div style={{fontSize:'17px',fontFamily:'arial',fontWeight:500}}>
                            Explore various articles, advices, answers and other resources from our team.
                        </div>
                    </div>
                </div>
                <div className='helpcenterArticlesContainer'>
                    {
                        Object.keys(data).map((keyItem,keyIndex)=>{
                            var item = data[keyItem]
                            return <div key={keyIndex} className={'helpcenterCategoryContainer'} onClick={()=>{if(selectedTab===keyItem){setSelectedTab(false);setSelectedSubTab(false);addToUrl('');}else{setSelectedTab(keyItem);addToUrl(keyItem)}}}>
                                    {item.img}
                                    <div style={{fontSize:'20px',marginTop:'30px',fontWeight:'500'}}>
                                        {keyItem}
                                    </div>
                                    <div style={{textAlign:'center',marginTop:'10px',fontSize:'12px',color:'rgb(120,120,120)'}}>
                                        {item.description}
                                    </div>

                                </div>
                        })
                    }
                </div>
                <div className='helpcenterWhatsnew'>
                    <div style={{fontSize:'38px',fontFamily:'Anton'}}>
                        What's new
                    </div>
                    <div className='helpcenterWhatsnewContainer'>
                        {newData.map((item,index)=>{
                            return <div className='helpcenterWhatsNewWrapper' key={index}>
                                    <img src={GraffitiImage} alt='' width={'100%'}/>
                                    <div className='helpcenterWhatsNewCategoryWrapper'>
                                        {item.keyType}
                                    </div>
                                    <div style={{fontSize:'22px',marginLeft:'15px',fontWeight:'600'}}>
                                        {item.articleName}
                                    </div>
                                </div>
                        })}
                    </div>
                </div>
            </>
            }
            {selectedTab
                &&
                <div style={{marginTop:'80px',display:'flex',justifyContent:'center'}}>
                    <div className='helpcenterTabContainer'>
                        {getNavigationBar('Help Center',selectedTab,selectedSubTab)}
                        {
                            !selectedSubTab && 
                            <div style={{padding:'20px',background:'rgb(255, 249, 237)',minHeight:'70vh'}}>
                                <div style={{display:'flex',alignItems:'center',paddingLeft:'5%'}}>
                                    <NaturePeopleIcon sx={{fontSize:'36px',marginRight:'20px'}}/>
                                    <div>
                                        <div style={{fontSize:'32px',fontWeight:500}}>App Analytics for {selectedTab}</div>
                                        <div style={{fontSize:'12px',marginTop:'5px'}}>{data[selectedTab].childItems.length} {data[selectedTab].childItems.length>1 ? 'articles' : 'article'} in this collection.</div>
                                        {/* <div><label>Written by:</label> Deepesh Motwani</div> */}
                                    </div>
                                </div>
                                {data[selectedTab].childItems.map((item,index)=>{
                                    return <div key={index} className='helpcenterArticleWrapper' onClick={()=>{setSelectedSubTab(item.articleName);addToUrl(selectedTab,item.articleName)}}>
                                            <div style={{fontSize:'20px',fontWeight:500,paddingLeft:'5px'}}>{item.articleName}</div>
                                            <div style={{display:'flex',alignItems:'center',marginTop:'10px'}}>
                                                <div style={{width:'25px',height:'25px',textAlign:'center',border:'1px solid rgb(150,150,150)',color:'rgb(150,150,150)',borderRadius:'50%',marginRight:'5px',display:'flex',alignItems:'center',justifyContent:'center'}}>{item.writtenBy[0]}</div>
                                                <div>
                                                    <div style={{fontSize:'12px',marginBottom:'1px',fontWeight:500}}><label style={{fontWeight:400,marginRight:'2px'}}>Written By:</label>{item.writtenBy}</div>
                                                    <div style={{fontSize:'12px'}}>Updated over a week ago.</div>
                                                </div>
                                            </div>
                                        </div>
                                })}
                            </div>
                        }
                        {
                            selectedSubTab
                            &&
                            <div style={{paddingLeft:'15%',paddingRight:'15%',paddingTop:'5%',background:'rgb(255, 249, 237,0.5)',minHeight:'70vh'}}>
                                {data[selectedTab].childItems.map((item,index)=>{
                                    if(item.articleName===selectedSubTab){
                                        return <div>
                                                {item.data}
                                            </div>
                                    }
                                    return null;
                                })}
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default HelpCenterMain