import React from "react";
// import ParticleComponent from "../components/particlesBackground";
import '../css/components/particles.css';
import '../css/pages/home.css';
import AboutUs from '../img/homepage.gif'
export default function HomePage(){

    return <div id='home' className='wrapper'>
            <div className="homeContainer">
                <div className="homeLeftWrapper">
                    <div className="hashtagLine">
                        <b>#New-Generation Tracking Solution</b>
                    </div>
                    <div className="mainHomeHeading">
                        Boost your <br/>Performance Marketing<br/> & Partnerships
                    </div>
                    <div className="mainHomeSubHeading">
                        Track, Analyze & Optimize your Campaigns on Real Time 
                    </div>

                    {/* Button */}
                    <div className="getStartedContainer">
                        <a href="/freetrial" target='_blank' rel='noreferrer' style={{textDecoration:'none'}}>
                            <div className="btn btn4">
                                    Get Started
                            </div>
                        </a>
                    </div>
                </div>
                <div className="homeRightWrapper">
                    <img src={AboutUs} alt="" className="homepageGif" style={{transform:'scale(1.2)'}}/>
                </div>
            </div>
        </div>
};
  