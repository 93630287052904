import axios from 'axios';
import React, { useState } from 'react'
import '../css/pages/contactus.css';
import messageIcon from '../img/messageIcon.png';
// import Footer from './footer';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Bars} from  'react-loader-spinner'

const ContactUsPage = () => {
    const [userDetails, setUserDetails] = useState({contact_time:'',contact_day:''})
    const [response, setResponse] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    
    var uploadSystem = async() => {
        setShowLoader(true);
        var contact_time_temp = parseInt(userDetails.contact_time) + parseInt(userDetails.contact_day);

        await axios({
            method: "POST",
            url: process.env.REACT_APP_BACKEND + "/api/savecontact",
            headers:{
                "Content-Type": "application/json",
            },
            data: { 
                "name":userDetails.name,
                "email-id":userDetails.email,
                "phone-number":userDetails.phonenumber,
                "skype-id":userDetails.skype ? userDetails.skype : "",
                "message":userDetails.question,
                'contact-time':contact_time_temp,
            },
        })
        .then(resp => {
            setResponse(true);
            setUserDetails({contact_time:'',contact_day:''});

            document.getElementById('name').value = ""
            document.getElementById('email').value = ""
            document.getElementById('phonenumber').value = ""
            document.getElementById('skype').value = ""
            document.getElementById('question').value = ""
            document.getElementById('contact_time').value = ""
            document.getElementById('contact_day').value = ""
        })
        .catch(err=>{
            setShowLoader(false);
            console.log(err)
        })
        
    }
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var contactDayOptions = () => {
        var todayDate = new Date()
        if(todayDate.getDay===0){
            todayDate = new Date(new Date().setDate(new Date().getDate() + 1))
        }
        var nextDayList = []
        var i = 1;
        var dateOptions = { weekday: 'short',  day: 'numeric' , month: 'short',};

        while(nextDayList.length!==7){
            var nextDay = new Date(todayDate.getTime() + 86400000*i)
            if(nextDay.getDay()!==0){
                nextDay.setHours(24,0,0,0)
                nextDayList.push({
                    label:nextDay.toLocaleDateString("en-US", dateOptions),
                    value:nextDay.getTime() //+ 19800000
                })
            }
            i += 1;
        }
        return nextDayList;
            
    }

    var contactTimeOptions = [
        {label:'10:00 AM - 11:00AM (GMT +05:30)',value:3600000 * 10},
        {label:'11:00 AM - 12:00PM (GMT +05:30)',value:3600000 * 11},
        {label:'12:00 PM - 01:00PM (GMT +05:30)',value:3600000 * 12},
        {label:'01:00 PM - 02:00PM (GMT +05:30)',value:3600000 * 13},
        {label:'02:00 PM - 03:00PM (GMT +05:30)',value:3600000 * 14},
        {label:'03:00 PM - 04:00PM (GMT +05:30)',value:3600000 * 15},
        {label:'04:00 PM - 05:00PM (GMT +05:30)',value:3600000 * 16},
        {label:'05:00 PM - 06:00PM (GMT +05:30)',value:3600000 * 17},
        {label:'06:00 PM - 07:00PM (GMT +05:30)',value:3600000 * 18},   
    ]

    return (
        <div className="contactUsContainer" onClick={()=>{if(response){setResponse(false);}}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                <div style={{fontSize:"30px",fontFamily:'Anton'}}>
                    Have Some Questions?
                </div>
                <div className='contactUsFormWrapper' style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'3%',background:'white',boxShadow:'0 0 5px 1px orange',paddingTop:'30px',paddingBottom:'10px',borderRadius:'20px',height:'max-content'}}>
                    <div style={{width:'30%',display:'flex',justifyContent:'flex-end',alignItems:'center',maxHeight:'100px',marginRight:'10%'}}>
                        <img src={messageIcon} alt='' width='75%'/>
                    </div>
                    <div style={{width:'70%',display:'flex',flexDirection:'column'}}>
                        <input id='name' className={userDetails.name!=="" ? 'inputTextField' : 'inputTextField invalid'} placeholder='Name*' onChange={e=>{setUserDetails({...userDetails,name:e.target.value})}}/>
                        
                        <div>
                            <input id='email' className={ userDetails.email!=="" ? 'inputTextField' : 'inputTextField invalid'} placeholder='Email*' onChange={e=>{setUserDetails({...userDetails,email:e.target.value})}}/>
                            {userDetails.email && !userDetails.email.match(emailRegex) && <div style={{position:'absolute',background:'rgb(255,200,200)',boxShadow:'0 0 2px 1px red',marginTop:'-1.5%',padding:'5px',borderRadius:'10px',fontSize:'10px'}}>Invalid Email</div>}
                        </div>
                        <input id='phonenumber' className={userDetails.phonenumber!=="" ? 'inputTextField' : 'inputTextField invalid'} placeholder='Mobile Number*' onChange={e=>{setUserDetails({...userDetails,phonenumber:e.target.value})}}/>
                    
                        <select  id='contact_day'  className='inputTextField' style={{minHeight:'32px',width:'84%',color:(userDetails.contact_day==="" ? 'rgb(135,135,135)' : "rgb(0,0,0)"),paddingLeft:'5px'}} onChange={(e)=>{setUserDetails({...userDetails,contact_day:e.target.value})}}>
                            <option disabled selected value=''>Select Contact Day*</option>
                            {contactDayOptions().map((item,index)=>{
                                return <option key={index} value={item.value}>{item.label}</option>
                            })}
                        </select>
                        
                        <select id='contact_time'  className='inputTextField' style={{minHeight:'32px',width:'84%',color:(userDetails.contact_time==="" ? 'rgb(135,135,135)' : "rgb(0,0,0)"),paddingLeft:'5px'}} onChange={(e)=>{setUserDetails({...userDetails,contact_time:e.target.value})}}>
                            <option disabled selected value=''>Select Contact Time*</option>
                            {contactTimeOptions.map((item,index)=>{
                                return <option key={index} value={item.value}>{item.label}</option>
                            })}    
                        </select>
                        
                        <input id='skype' className={'inputTextField'} placeholder='Skype ID' onChange={e=>{setUserDetails({...userDetails,skype:e.target.value})}}/>
                        
                       
                        <textarea id='question' className={userDetails.question!=="" ? 'inputTextField' : 'inputTextField invalid'} style={{minHeight:'90px'}} placeholder='Message*' onChange={e=>{setUserDetails({...userDetails,question:e.target.value})}}/>
                        <div style={{fontSize:'10px'}}>** Please enter all the details before submitting.</div>
                        <button style={{minWidth:'90px',display:'flex',justifyContent:'center'}} className={!userDetails.name || !userDetails.name || !(userDetails.skype || userDetails.phonenumber) || !userDetails.contact_day || !userDetails.contact_time  || !userDetails.question ? 'btn btn-disabled' : "btn"} onClick={uploadSystem} disabled ={!userDetails.name || !userDetails.name || !(userDetails.skype || userDetails.phonenumber) || !userDetails.question || !userDetails.contact_day || !userDetails.contact_time}>
                            {showLoader && 
                            <Bars              
                                ariaLabel="loading-indicator"
                                height={20}
                                width={20}
                                strokeWidth={5}
                                color="white"
                                />}
                            {!showLoader && <span>Submit</span>}
                        </button>
                        
                    </div>
                    {
                        response &&
                        <div style={{position:'absolute',width:'300px',minHeight:'200px',background:'white',boxShadow:'0 0 5px 1px grey',display:"flex",alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                            <img src={require('../img/success.png')} width='50px' alt=''></img>
                            <br/>
                            <div style={{textAlign:'center',userSelect:'none'}}>Your question has been recorded, <br/> someone will get in contact with you soon.</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ContactUsPage