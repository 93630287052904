import React from 'react'
import '../css/try.css';
import {Canvas} from "@react-three/fiber"
import { OrbitControls, PerspectiveCamera } from "@react-three/drei"

function CarShow() {

    return (
        <>
            <OrbitControls target={[0,0,0]} />
            <PerspectiveCamera makeDefault for={50} position={[3,2,5]}/>
            <mesh>
                <boxBufferGeometry attach="geometry" args={[3,3,3]} />
                <meshNormalMaterial attach="material"/>
            </mesh>
        </>
          )
}

const TryPage = () => {
    return (
        <div>
            <Canvas style={{height:'100vh'}}>
                <CarShow/>
            </Canvas>
        </div>
    )
}

export default TryPage