import React,{useState,useCallback,useEffect} from 'react';
import '../css/pages/about.css';
import { getCoords } from '../util';
import DashboardPng from '../img/dashboard.png'
const AboutPage = () => {
    const [lastScrollPosition, setLastScrollPosition] = useState(window.scrollY);
    // const [scrollUp, setScrollUp] = useState(false);
    
    const [aboutLabelProps, setAboutLabelProps] = useState({left:-200,opacity:0});

    const handleNavigation = useCallback(e => {
        const window = e.currentTarget;
        if (lastScrollPosition > window.scrollY) {
            // setScrollUp(true);
        } 
        else if (lastScrollPosition < window.scrollY) {
            // setScrollUp(false);
        }

        if(document.getElementById("aboutContainer")){
            var left = 0
            var opacity = 0
            var zoominImage = 0
            var aboutContainerTop = getCoords(document.getElementById("about")).top;
            // var innerWidthRatio = window.innerWidth/400
            var totalRatio = parseInt(((aboutContainerTop-0)/window.innerHeight)*100)
            var ratio = parseInt(((aboutContainerTop-window.scrollY)/window.innerHeight)*100)
            ratio = totalRatio - ratio
            if(ratio<totalRatio){
                // left = innerWidthRatio*(ratio-totalRatio);
                if(ratio<=30){
                    opacity = ratio*(0.2/totalRatio)
                    zoominImage = ratio*(10/totalRatio)

                    opacity = 1
                    zoominImage = 1
                }
                else if(ratio>30 && ratio<=50){
                    opacity = ratio*(0.2/totalRatio)
                    zoominImage = ratio*(1/totalRatio)

                    opacity = 1
                    zoominImage = 1
                }
                else if(ratio>50 && ratio<=90){
                    opacity = ratio*(0.3/totalRatio)
                    zoominImage = ratio*(1/totalRatio)

                    opacity = 1
                    zoominImage = 1
                }
                else{
                    opacity = ratio*(1/totalRatio)
                    zoominImage = ratio*(1/totalRatio)
                }
            }
            else{
                left = 0
                opacity = 1
                zoominImage = 1
            }
            var tempAboutLabelProps = aboutLabelProps
            tempAboutLabelProps['left'] = left
            tempAboutLabelProps['opacity'] = opacity
            tempAboutLabelProps['zoominImage'] = zoominImage

            setAboutLabelProps(tempAboutLabelProps)
        }
        setLastScrollPosition(window.scrollY);
    }, [lastScrollPosition,aboutLabelProps]
    );

    useEffect(() => {
        

        // console.log("leftValue",leftValue)
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);
    

    return (
        <div id='aboutContainer' className='aboutContainer'>
            <div className='aboutContainerTop'>
                <div className='aboutContent' style={{transform:'matrix(1, 0, 0, 1, '+aboutLabelProps.left+', 0)',opacity:aboutLabelProps.opacity}}>
                    <div className='aboutMainHeading'>Managing Partnerships Was Never Easy</div>
                    <div className='aboutSubHeading'>
                        App Analytics is Gen-X marketing solution, where you can manage and automate your partnership with Affiliates, Brands, Advertiser.  
                        <br/>
                        <br/>
                        Our SaaS Marketing Solution will uplift your marketing efforts through web & Mobile Platform.
                    </div>
                </div>
                <div className='flexRowAllCenter dashboardImage' style={{paddingLeft:'0px',transform:'scale('+aboutLabelProps.zoominImage+')',opacity:aboutLabelProps.opacity}}>
                    <img src={DashboardPng} alt='' width={'100%'} style={{borderRadius:'10px'}}/>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;