import './App.css';
import Topbar from './components/topbar';
import AboutPage from './pages/about';
import HomePage from './pages/home';
import FeaturesPage from './pages/features';
import SolutionsPage from './pages/solutions';
import ContactUsPage from './pages/contactus';
import PrivacyPolicyPage from './pages/privacyPolicy';
import SignUpPage from './pages/signup';
import Footer from './pages/footer';
import TryPage from './pages/try';
import FaqPage from './pages/faq';
import PricingPage from './pages/pricing';
// import AddFaq from './pages/addFaq';
import NewFeatures from './pages/newFeatures';
import { scrollToDIV } from './util';
import { useEffect } from 'react';
import HelpCenterMain from './pages/helpcenter/helpcenterMain';
// import Footer from './pages/footer';

function App() {

    useEffect(() => {
        if(document.getElementById('contactus')){
  
          if(window.location.pathname==='/about'){
              scrollToDIV('about',30)
          }
          if(window.location.pathname==='/features'){
              scrollToDIV('features',30)
          }
          if(window.location.pathname==='/solutions'){
              scrollToDIV('solutions',30)
          }
          if(window.location.pathname==='/contactus'){
              scrollToDIV('contactus',30)
          }
          }
          
          return () => {
              
          }
      }, [])

    if(window.location.pathname==='/privacypolicy'){
        return <PrivacyPolicyPage/>
    }

    if(window.location.pathname==='/try'){
        return <TryPage/>
    }
    
    if(window.location.pathname.includes('/helpcenter')){
        return <HelpCenterMain/>
    }
    if(window.location.pathname==='/newfeatures'){
        return <NewFeatures/>
    }

    if(window.location.pathname.includes('/faq')){
        return <FaqPage/>
    }

    // if(window.location.pathname.includes('/Add57030048810621021190')){
    //     return <AddFaq/>
    // }
    if(window.location.pathname==='/freetrial'){
        return <SignUpPage/>
    }

    if(window.location.pathname==='/pricing'){
        return <PricingPage/>
    }
    
    return (
        <div className="App">
            <Topbar/>
            <HomePage/>
            
            <div id='information'>
                <div id='about'>
                    <AboutPage/>
                </div>
                <div id='features'>
                    <FeaturesPage/>
               </div>
                <div id='solutions'>
                    <SolutionsPage/>
               </div>
                <div id='contactus'>
                    <ContactUsPage/>
               </div>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
